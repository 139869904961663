import React,{useEffect,useState} from 'react';
import { NoPageContainer } from './NopageStyle';
function Nopage() {
  return (
    <div>
      <NoPageContainer>
        <head>
        <meta charset="UTF-8"/>
        <meta name="viewport" conte nt="width=device-width, initial-scale=1.0"/>
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"/> */}
        <title>404</title>
        </head>
        <body>
        <main>
        <h1>40<span><i class="fa fa-industry"></i></span>4</h1>
        <h2>Error: 404 page not found</h2>
        <p>Sorry, the page you're looking for cannot be accessed</p>
        <a href="/"><button> Back to Home</button></a>
      </main>
        </body>
      </NoPageContainer>
    </div>
  )
}

export default Nopage
