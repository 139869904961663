import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link ,useParams} from 'react-router-dom';
import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";

function Product({ categoryList, backendUrl }) {
    let { categoryId } = useParams();
    //console.log(categoryId)
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const productsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
    const displayedProducts = filteredProducts.slice(
        (currentPage - 1) * productsPerPage,
        currentPage * productsPerPage
    );

    useEffect(() => {
        fetch(`${backendUrl}/getproductdetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify the content type if sending JSON data
          },
          body: JSON.stringify({ categoryId }), // Include categoryId in the request body
        })
          .then(response => response.json())
          .then(data => {
            setProducts(data.products);
            setCategories(data.categories);
            setFilteredProducts(data.products);
          })
          .catch(error => {
            console.error(error);
          });
      }, [backendUrl, categoryId]);
      const isActive = (id) => {
        return id === categoryId ? '' : 'collapsed'; 
      };

    useEffect(() => {
        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            "../../../js/main.js"
        ];

        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredProducts]);

    // useEffect(() => {
    //     const filtered = products.filter((product) => {
    //         const matchCategory = !selectedCategory || (
    //             product.categoryId && product.categoryId._id === selectedCategory._id
    //         );

    //         const matchSubcategory = !selectedSubcategory || (
    //             product.subCategoryId && product.subCategoryId === selectedSubcategory._id
    //         );

    //         return matchCategory && matchSubcategory;
    //     });

    //     console.log("Filtered products:", filtered);

    //     setFilteredProducts(filtered);
    // }, [selectedCategory, selectedSubcategory, products]);



    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
    };

    const handleSubcategoryClick = (subcategoryId, e) => {
        if (e) {
            e.preventDefault(); // Prevent the default link behavior
        }
        axios.post(`${backendUrl}/getProductDetailsbySubCategory`, { data: subcategoryId,id:1 })
            .then(response => {
                // setProducts(response.data.products);
                // setCategories(response.data.categories);
                setFilteredProducts(response.data.products);
            })
            .catch(error => {
                console.error('Error posting subcategory ID:', error);
            });
        //setSelectedSubcategory(subcategory);
    };
    const handlecategoryClick = (categoryId, e) => {
        if (e) {
            e.preventDefault(); // Prevent the default link behavior
        }
        axios.post(`${backendUrl}/getProductDetailsbySubCategory`, { data: categoryId,id:2 })
            .then(response => {
                // setProducts(response.data.products);
                // setCategories(response.data.categories);
                setFilteredProducts(response.data.products);
            })
            .catch(error => {
                console.error('Error posting subcategory ID:', error);
            });
        //setSelectedSubcategory(subcategory);
    };

    const handleLiveSearch = (e) => {
        const keyword = e.target.value;
        setSearchKeyword(keyword);

        if (keyword) {
            const filtered = products.filter(product =>
                product.productname.toLowerCase().includes(keyword.toLowerCase())
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(products);  // Reset to show all products when search is cleared
        }
        setCurrentPage(1); // Reset to the first page
    };

    return (
        <>
            <div className="hero-wrap hero-bread">
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-9 ftco-animate text-center">
                            <p className="breadcrumbs"><span className="mr-2"><a href="./">Home</a></span> <span>Products</span></p>
                            <h1 className="mb-0 bread">Products</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-4">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search products..."
                                value={searchKeyword}
                                onChange={handleLiveSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row">


                        <div className="col-md-4 col-lg-2" >
                            <div className="sidebar">
                                <div className="sidebar-box-2">
                                    <h2 className="heading">Categories</h2>
                                    <div className="fancy-collapse-panel">
                                        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            {categoryList.map((category, index) => (
                                                <div key={category._id} className="panel panel-default">
                                                    <div className="panel-heading" role="tab" id={`heading${index}`}>
                                                        <h4 className="panel-title">
                                                            <a
                                                                 className={`${isActive(category._id)}`}
                                                                data-toggle="collapse"
                                                                data-parent="#accordion"
                                                                href={`#collapse${index}`}
                                                                aria-expanded="false"
                                                                aria-controls={`collapse${index}`}
                                                                onClick={() => handlecategoryClick(category._id)}
                                                            >
                                                                {category.catename}
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id={`collapse${index}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`heading${index}`}>
                                                        <div className="panel-body">
                                                            <ul>
                                                                {category.subCategories && category.subCategories.map(subCategory => (
                                                                    <li key={subCategory._id}>
                                                                        {/* Add a custom class and use href="#" to prevent default behavior */}
                                                                        <a className="subcategory-link" href="#" onClick={() => handleSubcategoryClick(subCategory._id)}>
                                                                            {subCategory.subname}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-lg-10 order-md-last">
                            <div className="row">
                                {displayedProducts.map(product => (
                                    <div key={product._id} className="col-sm-6 col-md-6 col-lg-3 col-6 ftco-animate d-flex fadeInUp ftco-animated" style={{paddingBottom:'10px'}}>
                                        <Link to={`/product/${product._id}`} className="product-link" style={{ width: '100%' }}>
                                            <div className="product d-flex flex-column" style={{ height: '100%' }}>
                                                <div className="img-prod" style={{display:'flex',height:'66%'}}>
                                                    <img className="img-fluid" src={`/uploads/${product.image}`} alt="Product Image" />
                                                    <div className="overlay"></div>
                                                </div>
                                                <div className="text py-3 pb-4 px-3">
                                                    <div className="d-flex">
                                                        <div className="cat">
                                                            <span>{product.brand || "DENFIZ"}</span>
                                                        </div>
                                                    </div>
                                                    <h3>{product.productname}</h3>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>

                            <div className="row mt-5">
                                <div className="col text-center">
                                    <div className="block-27">
                                        <ul>
                                            <li><a href="#" onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}>&lt;</a></li>
                                            {[...Array(totalPages)].map((_, index) => (
                                                <li key={index} className={index + 1 === currentPage ? 'active' : ''}>
                                                    <a href="#" onClick={() => setCurrentPage(index + 1)}>{index + 1}</a>
                                                </li>
                                            ))}
                                            <li><a href="#" onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage)}>&gt;</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Product;
