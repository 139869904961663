import React, { useEffect } from 'react';

import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";

function Downloads({ documentList, backendUrl }) {
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);
    const handleDownload = (fileName) => {
        // Create a link element
        const link = document.createElement('a');
        link.href = `/uploads/${fileName}`;
        link.download = fileName;
    
        // Append the link to the body and programmatically trigger the click event
        document.body.appendChild(link);
        link.click();
    
        // Clean up by removing the link from the DOM
        document.body.removeChild(link);
    };
    
    return (
        <div>
            <>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <section>
                    <div class="container">
                        <div className="col-lg-12 ftco-animate">
                            <h4>Documents</h4>

                            {/* Check if the documentList is empty */}
                            {documentList.length === 0 ? (
                                <h5>No document available.</h5>
                            ) : (
                                documentList.map((documentItem, index) => (
                                    <div key={index}>
                                        {/* <h6>Product Name :- {documentItem.productName}</h6> */}
                                        <div className="dwld ftco-animate">
                                            <div className="file-dwnld">
                                                {/* <h6>{documentItem.document.instruction}</h6> */}
                                            </div>
                                            <div onClick={() => handleDownload(documentItem.document.documents)}>
                                                <a
                                                    href={`/uploads/${documentItem.document.documents}`}
                                                    download={documentItem.document.documents}
                                                >
                                                    <h6>
                                                        Download <i className="fa-solid fa-arrow-right"></i>
                                                    </h6>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </section>


                <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>
            </>
        </div>
    )
}

export default Downloads
