import React, { useEffect } from 'react';

import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";
function Events() {
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);
    return (
        <div>
            <>
                <section>
                    <div class="container" >
                        <div class="row">
                            <div class="col-lg-4 col-md-3 col-12 ftco-animate">
                                <div style={{
                                    textAlign: 'center',
                                    backgroundColor: 'rgb(235, 235, 235)',
                                    padding: '20px',
                                    borderRadius: '50px 50px 0 0',
                                    margin: '20px 0px'
                                }}>


                                    <h4 >Upcoming Events</h4>
                                    <p>September 20 2023</p>

                                    <div style={{
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(235, 235, 235)',
                                        padding: '20px',
                                        borderRadius: '0px 0px 0 0'
                                    }}>
                                        <div>
                                            <img src="images/products/OTHERS.jpg" style={{ width: '250px' }} alt="Product" />
                                        </div>
                                        <div>
                                            <h4>Dr. Marc Krikor Kaloustian</h4>
                                            <h5>CME UNDER PROCESS</h5>
                                            <p>When: Friday, 16 June 2023</p>
                                            <p><p>Time: 9:00 AM - 6:00 PM</p></p>
                                            <p>Where:Sheraton Abu Dhabi Hotel & Resorts</p>
                                            <p>Area of interest: Endodontics, General Dentistry</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-3 col-12 ftco-animate">
                                <div style={{
                                    textAlign: 'center',
                                    backgroundColor: 'rgb(235, 235, 235)',
                                    padding: '20px',
                                    borderRadius: '50px 50px 0 0',
                                    margin: '20px 0px'
                                }}>
                                    <h4 >Upcoming Events</h4>
                                    <p>September 20 2023</p>
                                    <div style={{
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(235, 235, 235)',
                                        padding: '20px',
                                        borderRadius: '0px 0px 0 0'
                                    }}>
                                        <div>
                                            <img src="images/products/OTHERS.jpg" style={{ width: '250px' }} />
                                        </div>
                                        <div>
                                            <h4>Dr. Marc Krikor Kaloustian</h4>
                                            <h5>CME UNDER PROCESS</h5>
                                            <p>When: Friday, 16 June 2023</p>
                                            <p><p>Time: 9:00 AM - 6:00 PM</p></p>
                                            <p>Where:Sheraton Abu Dhabi Hotel & Resorts</p>
                                            <p>Area of interest: Endodontics, General Dentistry</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>
                </section>
                <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>


            </>
        </div>
    )
}

export default Events
