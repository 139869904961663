import React, { useEffect } from 'react';

import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";
function About() {
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);

    return (
        <>
            <div>
                <div class="hero-wrap hero-bread">
                    <div class="container">
                        <div class="row no-gutters slider-text align-items-center justify-content-center">
                            <div class="col-md-9 ftco-animate text-center">
                                <p class="breadcrumbs"><span class="mr-2"><a href="./">Home</a></span> <span>About</span></p>
                                <h1 class="mb-0 bread">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>WHO WE ARE</h4>
                            <p>Denfiz is a leading supplier of dental consumables in the UAE. The company has established itself as a leader in the industry, through its dedication to the distribution of high-quality products from well-known manufacturers around the world. Denfiz is known within the UAE market for its exceptional performance and a highly motivated team, who provide unmatchable customer service. From sales and marketing to engineering, installation, and maintenance, customers can rest assured they will be looked after and guided through every step.

                                Headquartered in Abu Dhabi with branch offices in Dubai, Sharjah & Al Ain with large logistic facilities caters the need of UAE customers swiftly and efficiently. we can offer a “one-stop-shop”. Our international operations spans into Germany, Oman, Qatar, India & China with offices and manufacturing facilities.</p>
                            <h4>OUR MISSION</h4>
                            <p>Our mission is to transform our company into an admired, customer-friendly, and innovative dental solutions provider.</p>
                            <h4>OUR VISION</h4>
                            <p>Denfiz exists to improve the quality of human life by providing affordable, reliable, and innovative products.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="about">CORE VALUES AND BELIEFS</h4>
                            <ul>
                                <li>We are a market-driven company dedicated to providing affordable and excellent quality products to the community.</li>
                                <li>We believe we should contribute to society through our products, services, and the way we deliver them.</li>
                                <li>We are dedicated to quality in our products, in our service, and our relationships.</li>
                                <li>We respect our customers’ right to get value from our products and service.</li>
                                <li>We are responsible to our customers, to our employees, and to the society we serve</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>
        </>
    )
}

export default About
