import React from 'react'
import '../css/style.css';
import { Link } from 'react-router-dom';
function chunkArray(array, size) {
    const chunked_arr = [];
    let copied = [...array];
    const numOfChild = Math.ceil(copied.length / size);
    for (let i = 0; i < numOfChild; i++) {
        chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
}
function Header({ backendUrl, categoryList }) {
    const chunkedCategoryList = chunkArray(categoryList, 1); // Split category list into chunks of 3

    return (
        <div>
            <nav className="navbar navbar-light bg-light navbar-expand-lg" id="myNavbar">

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a href="/" className="navbar-brand"><img src="/images/logo.png" /></a>
                <div className="collapse navbar-collapse" id="mainNav">
                    <ul className="navbar-nav  nav-fill">

                        <li className="nav-item px-4">
                            <a href="/" className="nav-link">Home <span className="sr-only"></span></a>
                        </li>

                        <li className="nav-item px-4 dropdown">
                            <a className="nav-link dropdown-toggle" href="" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Products</a>

                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="servicesDropdown">
                                <div className="d-md-flex align-items-start justify-content-start">
                                    <a href="/products" className="dropdown-item" style={{ flex: '1', textAlign: 'left' }}>
                                        All Products
                                    </a>
                                </div>

                                {chunkedCategoryList.map((chunk, index) => (
                                    <div key={index} className="d-md-flex align-items-start justify-content-start">
                                        {chunk.map(category => (
                                            <div key={category._id} style={{ flex: '1', marginRight: '20px' }}>
                                                {/* Use Link instead of anchor tag */}
                                                <Link to={`/products/${category._id}`} className="dropdown-item">{category.catename}</Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </li>




                        <li className="nav-item px-4">
                            <a href="/About" className="nav-link">About</a>
                        </li>
                        <li className="nav-item px-4">
                            <a href="/downloads" className="nav-link">Download</a>
                        </li>
                        <li className="nav-item px-4">
                            <a href="/events" className="nav-link">Events</a>
                        </li>
                        <li className="nav-item px-4">
                            <a href="/distributors" className="nav-link">Distributors</a>
                        </li>
                        <li className="nav-item px-4">
                            <a href="/contact" className="nav-link">Contact</a>
                        </li>


                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header
