import React, { useEffect, useState } from 'react';
import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";
function ProductdetailsPage({ productDetails, backendUrl }) {
    const models = productDetails.model;
    const refrence = productDetails.refrence;
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);

    return (
        <>
            <div class="hero-wrap hero-bread">
                <div class="container">
                    <div class="row no-gutters slider-text align-items-center justify-content-center">
                        <div class="col-md-9 ftco-animate text-center">
                            <p class="breadcrumbs"><span class="mr-2"><a href="./">Home</a></span> <span>{productDetails.product.categoryId.catename}</span></p>
                            <h1 class="mb-0 bread">{productDetails.product.productname}</h1>

                        </div>
                    </div>
                </div>
            </div>

            <section class="ftco-section">
                <div class="container">
                    <div class="row">
                        <div className="col-lg-6 mb-5 ftco-animate">
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">

                                    {/* Main Product Image */}
                                    <div className="carousel-item active">
                                        <a href={`/uploads/${productDetails.product.image}`} className="image-popup prod-img-bg">
                                            <img src={`/uploads/${productDetails.product.image}`} className="d-block w-100" alt="..." />
                                        </a>
                                    </div>

                                    {/* Product Feature Images */}
                                    {productDetails.productPicturess.map(picture => (
                                        <div key={picture._id} className="carousel-item">
                                            <a href={`/uploads/${picture.image}`} className="image-popup prod-img-bg">
                                                <img src={`/uploads/${picture.image}`} className="d-block w-100" alt="..." />
                                            </a>
                                        </div>
                                    ))}

                                </div>
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>

                        <div class="col-lg-6 product-details pl-md-5 ftco-animate">
                            <h3>{productDetails.product.productname}</h3>
                            <ul>
                                {productDetails.productFeatures.map((feature) => {
                                    if (feature.featureStatus === 1) {
                                        return <li key={feature._id}>{feature.feature}</li>;
                                    }
                                    return null;
                                })}
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="table-responsive">
                        {refrence.length > 0 && (
                            <table className="table table-striped table-hover">
                                <thead className="thead-dark">
                                    <tr>
                                        <th></th>
                                        <th>Size</th>
                                        {
                                            // Get unique reference titles
                                            [...new Set(refrence.map(item => item.title))].map(title => (
                                                <th key={title}>{title}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {models.map((modelItem) => (
                                        <tr key={modelItem._id}>
                                            <td>
                                                <div
                                                    style={{
                                                        backgroundColor: modelItem.colorCode,
                                                        width: "25px",
                                                        height: "25px",
                                                        borderRadius: "50%"
                                                    }}
                                                >
                                                    &nbsp;
                                                </div>
                                            </td>
                                            <td>{modelItem.modelname}</td>
                                            {
                                                // For each unique reference title, check if the model has an associated item
                                                [...new Set(refrence.map(item => item.title))].map(title => {
                                                    const matchedRef = refrence.find(ref => ref.modelId === modelItem._id && ref.title === title);

                                                    return (
                                                        <td key={title}>
                                                            {matchedRef ? matchedRef.description : ''}
                                                        </td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}


                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="col-lg-12 ftco-animate">
                        {productDetails.productDocument.some(doc => doc.documents) && (
                            <>
                                <h4>Downloads</h4>
                                {productDetails.productDocument.map((document, index) => (
                                    document.documents && (
                                        <div className="dwld ftco-animate" key={index}>
                                            <div className="file-dwnld">
                                                <h6>{document.instruction}</h6>
                                            </div>
                                            <div>
                                                <a href={`/uploads/${document.documents}`} download={document.documents}>
                                                    <h6>Download <i className="fa-solid fa-arrow-right"></i></h6>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </section>


            <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>

        </>
    )
}

export default ProductdetailsPage
