import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";
function Contact({backendUrl}) {
    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        subject: '',
        description: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            formData.fullname === '' ||
            formData.email === '' ||
            formData.phone === '' ||
            formData.description === '' ||
            formData.subject === ''
        ) {
            showAlert('Warning!', 'Please Enter Basic Details', 'warning');
        } else if (!validateEmail(formData.email)) {
            showAlert('Warning!', 'Please enter a valid email address', 'warning');
        } else if (formData.phone.length < 8) {
            showAlert('Warning!', 'Phone No must be at least 8 characters long', 'warning');
        }

        else {
            const data = {
                lastname: '',
                firstname: formData.fullname,
                email: formData.email,
                phone: formData.phone,
                jobtitle: formData.subject,
                description: formData.description,
                enquirytype: 2
            };
            axios
                .post(`${backendUrl}/saveEnquiry`, data)
                .then((response) => {
                    showAlert('Success!', response.data.message, 'success');
                    clearForm();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    const validateEmail = (email) => {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };
    const showAlert = (title, text, icon) => {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: 'OK',
        });
    };
    const clearForm = () => {
        setFormData({
            fullname: '',
            email: '',
            phone: '',
            subject: '',
            description: '',
            enquirytype: '2',
        });
    };
    return (
        <div>
            <>
                <div class="hero-wrap hero-bread">
                    <div class="container">
                        <div class="row no-gutters slider-text align-items-center justify-content-center">
                            <div class="col-md-9 ftco-animate text-center">
                                <p class="breadcrumbs"><span class="mr-2"><a href="./">Home</a></span> <span>Contact</span></p>
                                <h1 class="mb-0 bread">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="ftco-section contact-section bg-light">
                    <div class="container">
                        <div class="row d-flex mb-5 contact-info">
                            <div class="w-100"></div>
                            <div class="col-md-4 d-flex">
                                <div class="info bg-white p-4">
                                    <p><span>Address:</span><div> Tower-1, Floor-6, Office 601, Mazyad Mall, Musaffa, Abu Dhabi</div></p>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex">
                                <div class="info bg-white p-4">
                                    <p><span>Phone:</span> <a href="tel://+971 55 521 9327
                ">+971 55 521 9327
                                    </a></p>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex">
                                <div class="info bg-white p-4">
                                    <p><span>Email:</span> <a href="mailto:contact@denfiz.com">contact@denfiz.com</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="row block-9">
                            <div class="col-md-6 order-md-last d-flex">

                                <form class="bg-white p-5 contact-form" id="ContactForm" onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Your Name" name='fullname' id='fullname' value={formData.fullname} onChange={handleInputChange} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Your Email" name='email' id='email' value={formData.email} onChange={handleInputChange} />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Subject" name='subject' id='subject' value={formData.subject} onChange={handleInputChange} />
                                    </div>
                                    <div class="form-group">
                                        <input type="tel" class="form-control" placeholder="Mobile number" name='phone' id='phone' value={formData.phone} onChange={handleInputChange} />
                                    </div>
                                    <div class="form-group">
                                        <textarea name="description" id="description" cols="30" rows="7" class="form-control" placeholder="Message" value={formData.description} onChange={handleInputChange}  ></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button class="btn btn-primary py-3 px-5" id="submit-contact-us">
                                            SEND MESSAGE
                                        </button>
                                    </div>
                                </form>

                            </div>

                            <div class="col-md-6 d-flex">
                                <div  >
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1765.3885508367819!2d54.53674098032591!3d24.373069045215118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e665db8946d1b%3A0x82f45d899860c37e!2sBin%20Ali%20Medical%20Supplies!5e0!3m2!1sen!2sae!4v1694593743529!5m2!1sen!2sae" width="auto" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>

            </>
        </div>
    )
}

export default Contact
