
import React, { useState, useEffect, Redirect } from 'react';
import Header from './Components/layout/Header';
import Index from './Components/pages/Index';
import Footer from './Components/layout/Footer';
import About from './Components/pages/About';
import Downloads from './Components/pages/Downloads';
import Events from './Components/pages/Events';
import Distributors from './Components/pages/Distributors';
import Contact from './Components/pages/Contact';
import Nopage from './Components/pages/Nopage';
import Product from './Components/pages/Product';
import axios from 'axios';
import ProductdetailsPage from './Components/pages/ProductdetailsPage';
import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from 'react-router-dom';
function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = () => {
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return <Nopage />;
  }

  return children;
}

function App() {
  const isLocalhost = window.location.hostname === 'localhost';
  //let backendUrl ='192.168.121.72';
  let backendUrl = isLocalhost
  ? 'https://denfiz.com/Denfiz'
   : 'https://denfiz.com/Denfiz';

  // let backendUrl = isLocalhost
  //   ? 'http://localhost:3002/Denfiz'
  //   : 'http://192.168.121.72:3002/Denfiz';


  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    // Function to fetch the category list from the backend
    const fetchCategoryList = async () => {
      try {
        const response = await fetch(`${backendUrl}/getCategoryWithSubcategory`, {

        });

        if (response.ok) {
          const data = await response.json();
          setCategoryList(data.categories);
        } else {
          console.error('Failed to fetch category list');
        }
      } catch (error) {
        console.error('Error fetching category list:', error);
      }
    };

    fetchCategoryList();
  }, [backendUrl]);

  const [documentList, setDocumentList] = useState([]);
  useEffect(() => {
    // Function to fetch the document list from the backend
    const fetchDocumentList = async () => {
      try {
        const response = await fetch(`${backendUrl}/getAllDocument`, {});
        if (response.ok) {
          const data = await response.json();
          setDocumentList(data); // Set the documentList state with the fetched data
        } else {
          console.error('Failed to fetch document list');
        }
      } catch (error) {
        console.error('Error fetching document list:', error);
      }
    };

    fetchDocumentList();
  }, [backendUrl]);

  return (
    <Router>
      <Header
        backendUrl={backendUrl}
        categoryList={categoryList}
      />
      <ErrorBoundary>
        <Routes>
          <Route path="" element={<Index categoryList={categoryList} backendUrl={backendUrl} />} />
          <Route path="/About" element={<About />} />

          <Route
            path="/products/:categoryId" // Define the parameter for categoryId
            element={<Product categoryList={categoryList} backendUrl={backendUrl} />}
          />
          <Route
            path="/products"
            element={<Product categoryList={categoryList} backendUrl={backendUrl} />}
          />
          <Route path="/downloads" element={<Downloads documentList={documentList} backendUrl={backendUrl} />} />
          <Route path="/events" element={<Events />} />
          <Route path="/distributors" element={<Distributors backendUrl={backendUrl} />} />
          <Route path="/contact" element={<Contact backendUrl={backendUrl} />} />

          <Route
            path="product/:id"
            element={<ProductdetailsPages backendUrl={backendUrl} />}
          />
          <Route path="*" element={<Nopage />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </Router>
  );
}
function ProductdetailsPages({ backendUrl }) {
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/getProductdetailsById/${id}`
        );
        const { productFeatures, productReference, categories, product, productPicturess, productColor, productDocument, model, refrence } = response.data;
        setProductDetails({ productFeatures, productReference, categories, product, productPicturess, productColor, productDocument, model, refrence });
      } catch (error) {
        console.error(error);
        // Handle error case
      }
    };

    fetchProductDetails();
  }, [backendUrl, id]);

  if (!productDetails) return null;  // Or return a loading spinner or some placeholder

  return <ProductdetailsPage productDetails={productDetails} backendUrl={backendUrl} />;
}
export default App;
