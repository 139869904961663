import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import "../../styles/css/open-iconic-bootstrap.min.css";
import "../../styles/css/animate.css";
import "../../styles/css/bootstrap/bootstrap.min.css";
import "../../styles/css/owl.carousel.min.css";
import "../../styles/css/owl.theme.default.min.css";
import "../../styles/css/magnific-popup.css";
import "../../styles/css/aos.css";
import "../../styles/css/ionicons.min.css";
import "../../styles/css/bootstrap-datepicker.css";
import "../../styles/css/jquery.timepicker.css";
import "../../styles/css/flaticon.css";
import "../../styles/css/icomoon.css";
import "../../styles/css/style.css";
import axios from 'axios';

function Index({ categoryList, backendUrl }) {

    useEffect(() => {
        const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

        const scripts = [
            "../../../js/jquery.min.js",
            "../../../js/jquery-migrate-3.0.1.min.js",
            "../../../js/popper.min.js",
            "../../../js/bootstrap.min.js",
            "../../../js/jquery.easing.1.3.js",
            "../../../js/jquery.waypoints.min.js",
            "../../../js/jquery.stellar.min.js",
            "../../../js/owl.carousel.min.js",
            "../../../js/jquery.magnific-popup.min.js",
            "../../../js/aos.js",
            "../../../js/jquery.animateNumber.min.js",
            "../../../js/bootstrap-datepicker.js",
            "../../../js/scrollax.min.js",
            //"https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false",
            // "../../../js/google-map.js",
            "../../../js/main.js"
        ];


        const loadScript = (src, callback) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.onload = callback;
            document.body.appendChild(scriptTag);
        };

        const loadScriptsSequentially = (scriptsToLoad) => {
            if (scriptsToLoad.length > 0) {
                const nextScript = scriptsToLoad.shift();
                loadScript(nextScript, () => loadScriptsSequentially(scriptsToLoad));
            }
        };

        loadScriptsSequentially([...scripts]);

    }, []);
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        subject: '',
        description: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            formData.fullname === '' ||
            formData.email === '' ||
            formData.phone === '' ||
            formData.description === '' ||
            formData.subject === ''
        ) {
            showAlert('Warning!', 'Please Enter Basic Details', 'warning');
        } else if (!validateEmail(formData.email)) {
            showAlert('Warning!', 'Please enter a valid email address', 'warning');
        } else if (formData.phone.length < 8) {
            showAlert('Warning!', 'Phone No must be at least 8 characters long', 'warning');
        }

        else {
            const data = {
                lastname: '',
                firstname: formData.fullname,
                email: formData.email,
                phone: formData.phone,
                jobtitle: formData.subject,
                description: formData.description,
                type: 2
            };
            axios
                .post(`${backendUrl}/saveEnquiry`, data)
                .then((response) => {
                    showAlert('Success!', response.data.message, 'success');
                    clearForm();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    const validateEmail = (email) => {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };
    const showAlert = (title, text, icon) => {
        Swal.fire({
            title: title,
            text: text,
            icon: icon,
            confirmButtonText: 'OK',
        });
    };
    const clearForm = () => {
        setFormData({
            fullname: '',
            email: '',
            phone: '',
            subject: '',
            description: '',
            enquirytype: '2',
        });
    };
    return (
        <>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="icon" type="image/x-icon" href="/images/icon.png" />
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800" rel="stylesheet" />
            <div className="goto-here">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100" src="images/banner-10.jpg" alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="images/Untitled-1.jpg" alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="images/hero_img-1500w.jpg" alt="Third slide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <section className="ftco-section ftco-no-pt ftco-no-pb">
                <div className="container">
                    <div className="row no-gutters ftco-services">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6 text-center d-flex align-self-stretch ">
                            <div className="media block-6 services p-4 py-md-5">
                                <div className="icon d-flex justify-content-center align-items-center mb-4">
                                    <img src="images/quality1.png" alt="Quality Icon" />
                                </div>
                                <div className="media-body">
                                    <h3 className="heading">Quality</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6 text-center d-flex align-self-stretch ">
                            <div className="media block-6 services p-4 py-md-5">
                                <div className="icon d-flex justify-content-center align-items-center mb-4">
                                    <img src="images/convenient.png" alt="Comfort Icon" />
                                </div>
                                <div className="media-body">
                                    <h3 className="heading">Comfort</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6 text-center d-flex align-self-stretch ftco-animate">
                            <div className="media block-6 services p-4 py-md-5">
                                <div className="icon d-flex justify-content-center align-items-center mb-4">
                                    <img src="images/project-management.png" alt="Technology Icon" />
                                </div>
                                <div className="media-body">
                                    <h3 className="heading">Technology</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="ftco-section bg-light">
                <div class="container">
                    <div class="row justify-content-center mb-3 pb-3">
                        <div class="col-md-12 heading-section text-center ftco-animate">
                            <h2 class="mb-4">Product Varieties</h2>
                            <p>Checkout our main categories</p>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        {categoryList.map(category => (
                            <div class="col-sm-6 col-md-4 col-lg-3 col-6 ftco-animate d-flex" key={category._id}>
                                <div class="product d-flex flex-column">
                                    {category.image ? (
                                        <Link to={`/products/${category._id}`} className="img-prod">
                                            <img className="img-fluid" src={`/uploads/${category.image}?${Date.now()}`} alt={category.catename} />
                                            <div className="overlay"></div>
                                        </Link>
                                    ) : (
                                        <Link to={`/products/${category._id}`} className="img-prod" style={{ height: '56%' }} >
                                            <img className="img-fluid" src="images/no_image.jpg" alt={category.catename} />
                                            <div className="overlay"></div>
                                        </Link>
                                    )}

                                    <div class="text py-3 pb-4 px-3">
                                        <div class="d-flex">
                                            <div class="cat">
                                                <span>DENFIZ</span>
                                            </div>
                                        </div>
                                        <h3><Link to={`/products/${category._id}`}>{category.catename}</Link></h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </section>

            <section class="ftco-section contact-section bg-light">
                <div class="container">
                    <h2 style={{ textAlign: 'center', backgroundColor: 'white', fontWeight: 600, fontSize: '25px' }}>Contact</h2>


                    <div class="row block-9 ">
                        <div class="col-md-12 order-md-last d-flex">

                            <form class="bg-white p-5 contact-form" id="ContactForm" onSubmit={handleSubmit}>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name" name='fullname' id='fullname' value={formData.fullname} onChange={handleInputChange} />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Email" name='email' id='email' value={formData.email} onChange={handleInputChange} />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Subject" name='subject' id='subject' value={formData.subject} onChange={handleInputChange} />
                                </div>
                                <div class="form-group">
                                    <input type="tel" class="form-control" placeholder="Mobile number" name='phone' id='phone' value={formData.phone} onChange={handleInputChange} />
                                </div>
                                <div class="form-group">
                                    <textarea name="description" id="description" cols="30" rows="7" class="form-control" placeholder="Message" value={formData.description} onChange={handleInputChange}  ></textarea>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary py-3 px-5" id="submit-contact-us">
                                        SEND MESSAGE
                                    </button>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </section>
            <section class="ftco-section ftco-choose ftco-no-pb ftco-no-pt">
                <div class="container">
                    <div class="row no-gutters">

                        <div class="col-lg-12">
                            <div class="heading-section ftco-animate mb-5">


                            </div>
                            <div class="carousel-testimony owl-carousel">
                                <div class="item">
                                    <div class="testimony-wrap">
                                        <img src="images/banner-10.jpg" />
                                    </div>
                                </div>
                                <div class="item">
                                    <img src="images/banner-10.jpg" />
                                </div>
                                <div class="item">
                                    <img src="images/banner-10.jpg" />

                                </div>
                                <div class="item">
                                    <img src="images/banner-10.jpg" />
                                </div>
                                <div class="item">
                                    <img src="images/banner-10.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div>

        </>
    );
}

export default Index;
