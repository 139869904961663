import React from 'react'
// import '../css/style.css';
function Footer() {
    return (
        <div>
            <footer class="ftco-footer ftco-section" style={{ background: '#858689' }}>
                <div class="container">
                    <div class="row">
                        <div class="mouse">
                            <a href="#" class="mouse-icon">
                                <div class="mouse-wheel"><span class="ion-ios-arrow-up"></span></div>
                            </a>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <a href="./"><img src="/images/logo.png" /></a>

                                <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                    <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                                    <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                                    <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4 ml-md-5">
                                <h2 class="ftco-heading-2">Menu</h2>
                                <ul class="list-unstyled">
                                    <li><a href="./" class="py-2 d-block">Home</a></li>
                        contact            <li><a href="shop.html" class="py-2 d-block">Products</a></li>
                                    <li><a href="/About" class="py-2 d-block">About US</a></li>
                                    <li><a href="/downloads" class="py-2 d-block">Downloads</a></li>
                                    <li><a href="/contact" class="py-2 d-block">Contact </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Useful Links</h2>
                                <div class="d-flex">
                                    <ul class="list-unstyled mr-l-5 pr-l-3 mr-4">
                                        <li><a href="#" class="py-2 d-block">Safety Data Sheet</a></li>
                                        <li><a href="#" class="py-2 d-block">Instruction for Use</a></li>
                                        <li><a href="#" class="py-2 d-block">Catalogues</a></li>
                                        <li><a href="#" class="py-2 d-block">Terms &amp; Conditions</a></li>
                                        <li><a href="#" class="py-2 d-block">Privacy Policy</a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="ftco-footer-widget mb-4">

                                <div class="block-23 mb-3">
                                    <ul>
                                        <li><span class="icon icon-map-marker"></span><span class="text">Safecare Medical GMBH Alsstr,97,41063,Monchengla bach,Germany</span></li>
                                        <li><a href="tel:+971 55 521 9327"><span class="icon icon-phone"></span><span class="text">+971 55 521 9327</span></a></li>
                                        <li><a href="mailto:contact@denfiz.com"><span class="icon icon-envelope"></span><span class="text">contact@denfiz.com</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">

                            <p>
                                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | Developed by  <a href="https://safecaretec.com/" target="_blank">Safecare Technology</a>

                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
